import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType, useToasts } from '../../contexts/ToastContext';
import Tooltip from './Tooltip';

const CopyText: FC<{ text: string; displayText?: string }> = (props) => {
  const { text, displayText = text } = props;
  const toasts = useToasts();
  const { t } = useTranslation('common');
  const [tooltipText, setTooltipText] = useState<string>(t('copy-text.tooltip'));

  const copyText = () => {
    navigator.clipboard.writeText(text);
    setTooltipText(t('copy-text.success'));
    toasts.addToast({
      type: ToastType.INFO,
      title: t('copy-text.success'),
      expiresInMs: 2000,
    });
    setTimeout(() => {
      setTooltipText(t('copy-text.tooltip'));
    }, 2000);
  };

  return (
    <Tooltip text={tooltipText}>
      {(tooltip) => (
        <div {...tooltip} className="relative cursor-pointer" onClick={copyText}>
          {displayText}
        </div>
      )}
    </Tooltip>
  );
};

export default CopyText;
